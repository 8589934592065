import { Text, Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { formatSalaryWithoutCurrency } from 'lib/utils/formatSalaryWithoutCurrency';

export enum SalarySuffixTypes {
  CLT = ' BRL/mês',
  PJ = ' BRL/mês',
  PJ_US = ' USD/ano',
  None = '',
}

export interface SalaryRangeProps {
  minSalary: number;
  maxSalary: number;
  currency: string;
  suffix: SalarySuffixTypes;
  isFilter?: boolean;
}

export const SalaryRange = ({
  minSalary,
  maxSalary,
  currency,
  suffix,
  isFilter = false,
}: SalaryRangeProps): ReactElement => (
  <Flex>
    <Text mr={1} color='text.700'>
      {currency}
    </Text>
    {formatSalaryWithoutCurrency(minSalary, 0)}
    <Text mx={1} color={isFilter ? 'primary.500' : undefined}>
      -
    </Text>
    {isFilter ? (
      <Text mr={1} color='text.700'>
        {currency}
      </Text>
    ) : null}
    {formatSalaryWithoutCurrency(maxSalary, 0)}
    {suffix ? (
      <Text ml={1} color='text.700'>
        {suffix}
      </Text>
    ) : null}
  </Flex>
);
