import { ReactElement, createElement } from 'react';
import { Text, TextProps, Box } from '@chakra-ui/react';

/**
 * Highlights the given words with the given style for the given text
 *
 * @param text the text to be queried and highlighted
 * @param starring the array containing the words to be highlighted
 * @param style the style of the highlighted words
 *
 * @returns the text with the highlighted words as a ReactElement
 */
export const starredText = (
  text: string,
  starring: string[],
  style: TextProps['style']
): ReactElement => {
  const textArray = text.split(' ');
  const starredTextArray = textArray.map((word, index, starredArray) => {
    const newWord = index + 1 === starredArray.length ? word : `${word} `;

    return starring.includes(word.replace(',', ''))
      ? createElement(
          Text,
          {
            as: 'span',
            display: 'inline',
            /* eslint-disable react/no-array-index-key */
            key: `${word}-${index}`,
            style,
            'data-testid': 'starred-text',
          },
          newWord
        )
      : newWord;
  });
  return createElement(
    Box,
    { 'data-testid': 'starred-container', display: 'inline', as: 'span' },
    starredTextArray
  );
};

/**
 * Concat words with a common separator and a last separator
 * @param words the array of words to be concatenated with a common separator
 * @param separator the separator to be used between the words
 * @param lastSeparator the separator to be used between the last two words
 * @returns the text concatenated with the given separator
 */
export const concatWords = (
  words: string[],
  separator = ',',
  lastSeparator = 'e'
): string => {
  const finalText = words.reduce((finalString, currentWord, index) => {
    if (index === words.length - 2) {
      return `${finalString}${currentWord} ${lastSeparator} `;
    }
    return `${finalString}${currentWord}${
      index !== words.length - 1 ? `${separator} ` : ''
    }`;
  }, '');
  return finalText;
};
