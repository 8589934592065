import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';

import { TRANSLATE_NAMESPACES } from 'lib/constants/translateNamespaces';

interface CountryFlagProps {
  flag: FlagEnum;
  height?: number;
}

export enum FlagEnum {
  en = 'en',
  br = 'pt_BR',
  usFlag = 'us_flag',
  brFlag = 'br_flag',
}

export const CountryFlag = ({
  flag,
  height = 25,
}: CountryFlagProps): ReactElement => {
  const { t } = useTranslation(TRANSLATE_NAMESPACES.global);
  return (
    <span
      style={{
        position: 'relative',
        display: 'inline-block',
        height: `${height}px`,
        width: `${height}px`,
      }}
    >
      <Image src={`/flags/${flag}.png`} layout='fill' alt={t(`flag.${flag}`)} />
    </span>
  );
};
