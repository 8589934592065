import { ReactElement } from 'react';
import {
  Box,
  Container,
  Text,
  Grid,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import Link from 'next/link';

import { FindLatestApprovedJobsQuery } from 'lib/generated/graphql';
import { JobCard } from 'components/molecules/home/jobCard';
import * as paths from 'lib/constants/paths';
import Button from 'components/atoms/button';
import Heading from 'components/atoms/heading';

export interface HomePageJobsProps {
  jobs: FindLatestApprovedJobsQuery['findLatestApprovedJobs'];
}

export const HomePageJobs = ({ jobs }: HomePageJobsProps): ReactElement => (
  <Box bgColor='background.750'>
    <Container maxW='container.xxl' paddingY={12}>
      <Text
        textTransform='uppercase'
        color='primary.500'
        fontWeight={700}
        textAlign='center'
        mb={4}
      >
        AUMENTE AS CHANCES DO SEU PERFIL SER VISTO PELAS EMPRESAS
      </Text>

      <Heading as='h2' textAlign='center' fontWeight={600} mb={10}>
        Crie sua conta e demonstre
        <br />
        interesse nas vagas
      </Heading>

      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        gap={4}
        mb={12}
      >
        {jobs.map((job) => (
          <JobCard
            key={job.id}
            acceptRemote={job.remoteWork ?? false}
            hybrid={job.hybrid}
            city={job.city?.name ?? ''}
            pjSalaryMax={job.pjMaxSalary}
            pjSalaryMin={job.pjMinSalary}
            cltSalaryMax={job.cltMaxSalary}
            cltSalaryMin={job.cltMinSalary}
            usdAnnualSalaryMin={job.usdAnnualSalaryMin}
            usdAnnualSalaryMax={job.usdAnnualSalaryMax}
            slug={job.slug ?? ''}
            techs={job.technologies ?? []}
            hideSalary={job.hideSalary}
            title={job.title ?? ''}
            isInternational={job.isInternational}
          />
        ))}
      </SimpleGrid>

      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        gap={4}
        justifyItems={{ base: 'center', sm: 'unset' }}
      >
        <GridItem justifySelf={{ base: 'center', md: 'right' }}>
          <Link href={paths.CANDIDATE_LP} passHref>
            <Button p={4}>Criar conta como profissional</Button>
          </Link>
        </GridItem>
        <GridItem justifySelf={{ base: 'center', md: 'left' }}>
          <Link href={paths.JOBS} passHref>
            <Button p={3} variant='outline'>
              Ver mais vagas
            </Button>
          </Link>
        </GridItem>
      </Grid>
    </Container>
  </Box>
);
