import { ReactElement } from 'react';
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  Box,
} from '@chakra-ui/react';

import { PropsWithChildren } from 'lib/@types';

export type HeadingFormat = 'h1' | 'h2' | 'h3' | 'h4';

export interface HeadingProps extends ChakraHeadingProps {
  format?: HeadingFormat;
  boostSizeLength?: number;
  boostFontSize?: string;
}

const fontSizes: Record<HeadingFormat, string[]> = {
  h4: ['14px', '14px', '16px', '16px'],
  h3: ['16px', '16px', '20px', '20px'],
  h2: ['28px', '28px', '28px', '28px'],
  h1: ['36px', '36px', '36px', '36px'],
};

const boostFontSizes: Record<HeadingFormat, string[]> = {
  h4: ['28px', '28px', '28px', '28px'],
  h3: ['40px', '40px', '48px', '48px'],
  h2: ['48px', '48px', '60px', '60px'],
  h1: ['60px', '60px', '68px', '68px'],
};

const fontWeights: Record<HeadingFormat, string> = {
  h4: '500',
  h3: '500',
  h2: '600',
  h1: '600',
};

const Heading = ({
  children,
  format = 'h1',
  boostSizeLength = 0,
  pt = 2,
  mt = 2,
  ...props
}: PropsWithChildren<HeadingProps>): ReactElement => (
  <ChakraHeading
    as={format}
    fontSize={
      boostSizeLength
        ? boostFontSizes[format]
        : fontSizes[format] || fontSizes.h1
    }
    fontWeight={fontWeights[format] || fontWeights.h1}
    letterSpacing='1px'
    color='text.100'
    lineHeight='normal'
    data-testid='heading'
    pt={pt}
    mt={mt}
    {...props}
  >
    {boostSizeLength && (children as string) ? (
      <>
        {(children as string).slice(0, boostSizeLength)}
        <Box display='inline' fontSize={fontSizes[format] || fontSizes.h1}>
          {(children as string).slice(boostSizeLength)}
        </Box>
      </>
    ) : (
      children
    )}
  </ChakraHeading>
);

export default Heading;
