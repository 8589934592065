import { ReactElement } from 'react';
import Image, { ImageProps } from 'next/image';
import { useBreakpointValue } from '@chakra-ui/react';

export interface BrandLogoProps extends ImageProps {
  src: string;
  alt: string;
  w?: number[] | Record<string, number>;
  h?: number[] | Record<string, number>;
}

const BrandLogo = ({
  src,
  alt,
  w = [130],
  h = [60],
  ...props
}: BrandLogoProps): ReactElement => {
  const width = useBreakpointValue(w);
  const height = useBreakpointValue(h);

  if (width && height) {
    return (
      <Image src={src} alt={alt} width={width} height={height} {...props} />
    );
  }

  return <></>;
};

export default BrandLogo;
