import { ReactElement, useState } from 'react';
import { BoxProps, Flex, Text, useToken, Box } from '@chakra-ui/react';
import Image from 'next/image';
import { motion } from 'framer-motion';

import { PropsWithChildren } from 'lib/@types';
import { starredText } from 'lib/utils/textUtils';

export interface TestimonialsCardProps extends BoxProps {
  coverText: {
    text: string;
    starring: string[];
  };
  backText: string;
  src: string;
  alt: string;
  name: string;
  job: string;
}

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const variants = {
  flipFront: { rotateY: '180deg', transition: { duration: 0.5 } },
  flipBack: { rotateY: '0deg', transition: { duration: 0.5 } },
  hideBack: { rotateY: '-180deg', transition: { duration: 0.5 } },
};

const TestimonialCard = ({
  coverText,
  backText,
  src,
  alt,
  name,
  job,
  ...props
}: PropsWithChildren<TestimonialsCardProps>): ReactElement => {
  const primary500 = useToken('colors', 'primary.500');
  const [isHovered, setHovered] = useState(false);
  const toggleIsHovered = () => setHovered(!isHovered);

  return (
    <MotionBox
      h={{ base: 410, md: 500, lg: 410 }}
      maxW={{ base: 320, sm: 480 }}
      position='relative'
      onMouseEnter={toggleIsHovered}
      onMouseLeave={toggleIsHovered}
      style={{ perspective: 1000 }}
    >
      <MotionFlex
        flexDirection='column'
        justifyContent='space-between'
        boxShadow='md'
        paddingY={8}
        paddingX={6}
        mb={{ base: 6, md: 0 }}
        bgColor='background.700'
        position='absolute'
        h='full'
        style={{
          backfaceVisibility: 'hidden',
        }}
        animate={isHovered ? 'flipFront' : 'flipBack'}
        variants={variants}
        {...props}
      >
        <Text fontSize='2xl' fontWeight={600} lineHeight={8}>
          {starredText(coverText.text, coverText.starring, {
            color: primary500,
          })}
        </Text>
        <Text
          textTransform='uppercase'
          color='primary.500'
          textDecoration='underline'
          fontSize='sm'
        >
          Ver depoimento
        </Text>
      </MotionFlex>
      <MotionFlex
        flexDirection='column'
        justifyContent='space-between'
        boxShadow='md'
        paddingY={8}
        paddingX={6}
        mb={{ base: 6, md: 0 }}
        bgColor='background.700'
        h='full'
        style={{
          backfaceVisibility: 'hidden',
        }}
        animate={isHovered ? 'flipBack' : 'hideBack'}
        variants={variants}
        initial='hideBack'
        {...props}
      >
        <Text color='text.200'>{backText}</Text>
        <Flex>
          <Image width={51} height={51} src={src} alt={alt} />
          <Flex ml={3} justifyContent='center' flexDirection='column'>
            <Text color='primary.500' fontSize='sm'>
              {name}
            </Text>
            <Text fontSize='sm'>{job}</Text>
          </Flex>
        </Flex>
      </MotionFlex>
    </MotionBox>
  );
};

export { TestimonialCard };
