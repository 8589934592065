import {
  Text,
  Badge as BadgeChakra,
  BadgeProps as BadgePropsChakra,
  Box,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import { VariantsAvailable, variants } from './variants';

export interface BadgeProps extends BadgePropsChakra {
  variant?: VariantsAvailable;
  text: string;
  isClosable?: boolean;
  onClose?: (e: Event) => void;
}

export const Badge = ({
  variant = 'remote',
  text,
  isClosable = false,
  onClose,
  ...props
}: BadgeProps): ReactElement => (
  <BadgeChakra
    fontWeight={600}
    borderRadius='base'
    fontSize='xs'
    letterSpacing='thight'
    px={2}
    py={1}
    {...variants[variant]}
    {...props}
    display='flex'
    alignItems='center'
  >
    <Text>{text}</Text>
    {isClosable && (
      <Box
        _hover={{
          textColor: 'black',
          bgColor: '#00000010',
        }}
      >
        <AiOutlineClose
          onClick={(e) => onClose && onClose(e as unknown as MouseEvent)}
        />
      </Box>
    )}
  </BadgeChakra>
);
