import { ReactElement } from 'react';
import { BoxProps, Box, Text } from '@chakra-ui/react';
import Image from 'next/image';

import Heading from 'components/atoms/heading';
import { PropsWithChildren } from 'lib/@types';

export interface JobSearchTypeProps extends BoxProps {
  src: string;
  imageHeight: number;
  imageWidth: number;
  alt: string;
  title: string;
  subtitle: string;
  text: string;
}

const JobSearchType = ({
  src,
  imageHeight,
  imageWidth,
  alt,
  title,
  subtitle,
  text,
  ...props
}: PropsWithChildren<JobSearchTypeProps>): ReactElement => (
  <Box
    bgColor='background.750'
    maxW={{ base: 320, sm: 350, md: 'full' }}
    h={386}
    pt={6}
    paddingX={12}
    {...props}
  >
    <Heading
      format='h2'
      color='primary.500'
      fontWeight={600}
      textAlign='center'
      mb={2}
    >
      {title}
    </Heading>
    <Text textAlign='center' fontSize='2xl' fontWeight={600} mb={4}>
      {subtitle}
    </Text>
    <Text textAlign='center' maxW={400} color='text.200' mb={10}>
      {text}
    </Text>
    <Image
      src={src}
      height={imageHeight}
      width={imageWidth}
      layout='responsive'
      alt={alt}
    />
  </Box>
);

export { JobSearchType };
