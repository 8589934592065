import { ButtonProps } from '@chakra-ui/react';

export const outline: ButtonProps = {
  _hover: {
    bgColor: 'primary.500',
    borderColor: 'primary.500',
    color: 'white',
  },
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'primary.500',
  bgColor: 'transparent',
  color: 'primary.500',
};
