/**
 * Formats salary without currency and with decimals.
 * Example: 9.837,00
 *
 * @param salary {number | undefined}
 * @param decimals {number}
 *
 * @returns {string}
 */
export const formatSalaryWithoutCurrency = (
  salary?: number | undefined,
  decimals = 2
): string => {
  const format = {
    minimumFractionDigits: decimals,
    currency: 'BRL',
  };

  if (!salary && salary !== 0) return '';

  return salary.toLocaleString('pt-BR', format);
};
