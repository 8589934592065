import { ReactElement } from 'react';
import { Box, Container, Text, Grid, GridItem, Flex } from '@chakra-ui/react';

import Heading from 'components/atoms/heading';
import { starredText } from 'lib/utils/textUtils';
import { TestimonialCard } from 'components/molecules/home/testimonialsCard';

export const HomePageTestimonials = (): ReactElement => (
  <Box bgColor='background.200'>
    <Container maxW='container.lg' paddingY={12}>
      <Flex flexDirection='column' alignItems='center'>
        <Text
          textTransform='uppercase'
          color='primary.500'
          fontWeight={700}
          textAlign='center'
          mb={4}
        >
          Para se inspirar
        </Text>
        <Heading
          textAlign='center'
          as='h2'
          fontWeight={600}
          mb={10}
          w={{ base: 'full', sm: 490 }}
        >
          {starredText(
            'Vidas transformadas pela GeekHunter',
            ['Vidas', 'transformadas'],
            {
              fontWeight: '700',
            }
          )}
        </Heading>
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(3, 1fr)',
          }}
          gap={3}
          justifyItems='center'
          maxW={1000}
        >
          <GridItem>
            <TestimonialCard
              coverText={{
                text: 'Guilherme adorou como foi atendido pela nossa equipe e foi contratado na ActualSales 💸',
                starring: ['Guilherme', 'nossa equipe', 'ActualSales'],
              }}
              backText='A GeekHunter me surpreendeu! A plataforma é bastante eficaz! Vale também falar da atenção dos funcionários. Todos que falaram comigo me trataram super bem e sempre acompanharam todo o processo de seleção, tirando dúvidas e ajudando no que fosse necessário. Recomendo muito!'
              name='Guilherme Assemany'
              job='Gerente de Projetos'
              src='/geeks/geek1.webp'
              alt='Foto do gerente de projetos Guilherme Assemany'
            />
          </GridItem>
          <GridItem>
            <TestimonialCard
              coverText={{
                text: 'Daniela amou a plataforma e foi contratada como desenvolvedora Full-Stack na Sislogica 🚚',
                starring: ['Daniela', 'desenvolvedora', 'Sislogica'],
              }}
              backText='Quando me cadastrei na GeekHunter já comecei gostando muito da plataforma. Ela tem tudo que um profissional da área de TI precisa mostrar para as empresas. Achei muito legal os feedbacks que enviam por email te atualizando sobre os processos seletivos e as dicas que dão para melhorar o perfil e ser mais visto entre as empresas.'
              name='Daniela Cruz Tirado'
              job='Desenvolvedora'
              src='/geeks/geek2.webp'
              alt='Foto da desenvolvedora Daniela Cruz Tirado'
            />
          </GridItem>
          <GridItem>
            <TestimonialCard
              coverText={{
                text: 'Fábio fez sua primeira entrevista após uma semana aprovado e foi contratado pela Tapps Games 🎮',
                starring: ['Fábio', 'entrevista', 'uma semana', 'Tapp Games'],
              }}
              backText='Me interessei pelo modelo de contratação e me cadastrei. As provas foram simples e diretas, mas sempre cobrando algum conteúdo chave. Depois de aprovado não demorou uma semana até a primeira entrevista. Adorei o lugar e estou nele até hoje. Recomendo e muito este serviço para todos os meus amigos da área.'
              name='Fabio Gunkel'
              job='Desenvolvedor'
              src='/geeks/geek3.webp'
              alt='Foto do desenvolvedor Fabio Gunkel'
            />
          </GridItem>
        </Grid>
      </Flex>
    </Container>
  </Box>
);
