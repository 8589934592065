import { ButtonProps } from '@chakra-ui/react';

import { outline } from './outline';
import { solid } from './solid';
import { outlineNewPurple } from './outlineNewPurple';
import { solidNewPurple } from './solidNewPurple';
import { disabledSolid } from './disabledSolid';
import { outlineRed } from './outlineRed';

export type VariantsAvailable =
  | 'outline'
  | 'solid'
  | 'outlineNewPurple'
  | 'solidNewPurple'
  | 'disabledSolid'
  | 'outlineRed';

export const variants: Record<VariantsAvailable, ButtonProps> = {
  outline,
  solid,
  outlineNewPurple,
  solidNewPurple,
  disabledSolid,
  outlineRed,
};
