import { ReactElement } from 'react';
import { Box, Container, Flex, Grid, GridItem, Center } from '@chakra-ui/react';
import Link from 'next/link';

import Button from 'components/atoms/button';
import BrandLogo from 'components/atoms/images/brandLogo/index';
import { CompaniesCard } from 'components/molecules/home/companiesCard';
import * as paths from 'lib/constants/paths';

export interface CompaniesLogoListProps {
  src: string;
  alt: string;
  w: number[] | Record<string, number>;
  h: number[] | Record<string, number>;
}

const companiesLogos: CompaniesLogoListProps[] = [
  {
    src: '/companies/mercado-livre-color-new.png',
    alt: 'Mercado Livre',
    w: [131],
    h: [33],
  },
  {
    src: '/companies/rank-my-app-color-new.png',
    alt: 'Rank My App',
    w: [121],
    h: [42],
  },
  { src: '/companies/zup-color-new.png', alt: 'Zup', w: [85], h: [41] },
  { src: '/companies/amazon-color-new.png', alt: 'Amazon', w: [114], h: [34] },
  {
    src: '/companies/hostgator-color.png',
    alt: 'Hostgator',
    w: [144],
    h: [25],
  },
];

export const HomePageCompanies = (): ReactElement => (
  <Box bgColor='background.850'>
    <Container maxW='container.lg' paddingY={12}>
      <Flex
        justify='space-between'
        alignItems='center'
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        mb={12}
      >
        <Grid
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
          templateRows={{
            base: 'repeat(3, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          maxW={600}
          gap={4}
        >
          {companiesLogos.map((companieLogo) => (
            <Center key={companieLogo.alt}>
              <GridItem
                p={[2, 2, 8, 8]}
                key={companieLogo.alt}
                data-testid='company-logo'
                textAlign='center'
              >
                <BrandLogo
                  src={companieLogo.src}
                  alt={companieLogo.alt}
                  h={companieLogo.h}
                  w={companieLogo.w}
                />
              </GridItem>
            </Center>
          ))}
        </Grid>
        <CompaniesCard
          subtitle='GEEKHUNTER PARA EMPRESA'
          title='Contrate os melhores talentos do mercado tech para a sua empresa'
          starredTitle={['para', 'a', 'sua', 'empresa']}
          text='Busque por profissionais e receba recomendações certeiras, de acordo com a sua necessidade. Contrate rápido e contrate bem.'
        />
      </Flex>
      <Center>
        <Link
          href={{
            pathname: paths.COMPANY_PLANS_LP,
          }}
          passHref
        >
          <Button p={4}>Confira nossos planos</Button>
        </Link>
      </Center>
    </Container>
  </Box>
);
