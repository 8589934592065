import { ReactElement } from 'react';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import * as paths from 'lib/constants/paths';
import Button from 'components/atoms/button';
import Heading from 'components/atoms/heading';
import ImageBanner from '@public/home/imagem-home.png';

import { Circles } from './circles';

export const HomePageBanner = (): ReactElement => (
  <Box bgColor='black.300' position='relative' overflow='hidden'>
    <Container
      maxW='container.lg'
      overflow='hidden'
      paddingX={{ base: 8, xl: 0 }}
      position='relative'
    >
      <Flex
        alignItems='flex-start'
        color='primary.50'
        flexDir='column'
        paddingBottom={20}
        paddingTop={36}
      >
        <Heading as='h1' mb={4} color='text.300'>
          As melhores vagas
          <br />
          em empresas de
          <br />
          tecnologia 🚀
        </Heading>

        <Text fontSize='0.9rem' mb={8}>
          Cadastre-se e comece sua experiência com a Geek
        </Text>

        <Link href={paths.CANDIDATE_LP} passHref>
          <Button p={4} mb={4}>
            Criar perfil grátis
          </Button>
        </Link>

        <Flex fontSize='0.9rem' flexDirection={{ base: 'column', sm: 'row' }}>
          <Text mr={1}>Você é empresa? </Text>
          <Link
            href={{
              pathname: paths.COMPANY_LP,
            }}
            passHref
          >
            <Text cursor='pointer' textDecor='underline'>
              Contrate os melhores talentos do mercado
            </Text>
          </Link>
        </Flex>
      </Flex>

      <Box
        bottom={10}
        display={{ base: 'none', lg: 'block' }}
        position='absolute'
        right={0}
      >
        <Image
          alt='Homem recem contratado comemorando'
          height={370}
          layout='fixed'
          priority
          src={ImageBanner}
          width={580}
        />
      </Box>
    </Container>

    <Circles />
  </Box>
);
