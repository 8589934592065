/** NAME SPACES */

const namespaces = {
  createProfile: 'criar_perfil_gratis',
  statusCandidateOffLimits: 'status_candidate_off_limits',
  techRecuiterSignin: 'tech_recruiter_signin',
  companyCandidates: 'companies_candidatos',
  companyAcceptTermsOfUse: 'companies_accept_terms_of_use',
  companyPausedPackage: 'companies_paused_package',
  companySecuritySettings: 'companies_security_settings',
  global: 'global',
  companiesSignupFlow: 'companies_signup_flow',
  menuHeader: 'menu_header',
  plataformFooter: 'plataform_footer',
  candidateDashboard: 'candidate_dashboard',
  companySubscription: 'companies_subscription',
  companyHubSalaries: 'companies_hub_salaries',
  companyHub: 'companies_hub',
};

// TRANSLATE_NAMESPACES is now an immutable object, so no import will be able to change it
export const TRANSLATE_NAMESPACES = Object.freeze(namespaces);
