import { forwardRef } from 'react';
import {
  Button as ButtonChakra,
  ButtonProps as ButtonPropsChakra,
  Icon as IconChakra,
  IconProps,
} from '@chakra-ui/react';

import { IconType } from 'lib/@types';

import { VariantsAvailable, variants } from './variants';

export type ButtonIconProps = Omit<IconProps, 'css'>;
export interface ButtonProps
  extends Omit<ButtonPropsChakra, 'rightIcon' | 'leftIcon'> {
  variant?: VariantsAvailable;
  rightIconProps?: ButtonIconProps;
  leftIconProps?: ButtonIconProps;
  rightIcon?: IconType;
  leftIcon?: IconType;
  size?: ButtonPropsChakra['size'] | 'xl';
  p?: string | number;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solid',
      rightIconProps,
      leftIconProps,
      rightIcon,
      leftIcon,
      children,
      p = '8px 16px',
      ...props
    },
    ref
  ) => (
    <ButtonChakra
      borderRadius={4}
      fontFamily='heading'
      fontSize='12px'
      fontWeight='700'
      letterSpacing='1.5px'
      ref={ref}
      p={p}
      textTransform='uppercase'
      leftIcon={
        leftIcon && <IconChakra as={leftIcon} w={5} h={5} {...leftIconProps} />
      }
      rightIcon={
        rightIcon && (
          <IconChakra as={rightIcon} w={5} h={5} {...rightIconProps} />
        )
      }
      isDisabled={variant === 'disabledSolid'}
      data-testid='button'
      {...variants[variant]}
      {...props}
    >
      {children}
    </ButtonChakra>
  )
);

Button.displayName = 'Button';

export default Button;
