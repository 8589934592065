import { ThemeColors } from '../types';

export const companyColorScheme: ThemeColors = {
  errors: {
    100: '#FCEAEF',
    150: '#FB5A82',
    200: '#B81A4C',
  },
  primary: {
    50: '#FFFFFF',
    100: '#F0F0FF',
    150: '#f1ddfc',
    300: '#C570F3',
    500: '#9412DC',
    700: '#6E2B77',
    800: '#63266B',
    900: '#58225f',
  },
  secondary: {
    100: '#41A9B9',
    200: '#E0FBF6',
  },
  background: {
    50: '#939DA5',
    100: '#11273A',
    200: '#E2EFF1',
    700: '#FFFFFF',
    750: '#F8F8F8',
    800: '#f0f0ff',
    850: '#FBFBFB',
    900: '#F0F0FF',
    950: '#C7CBCF',
  },
  text: {
    100: '#000000',
    150: '#040C10',
    200: '#727272',
    300: '#6B757D',
  },
  text_attention: {
    100: '#E8A217',
  },
};
