import { ReactElement } from 'react';
import { Box, Container, Grid, GridItem, Text, Center } from '@chakra-ui/react';

import BrandLogo from 'components/atoms/images/brandLogo/index';

export interface CompaniesLogoListProps {
  src: string;
  alt: string;
  w: number[] | Record<string, number>;
  h: number[] | Record<string, number>;
}

const companiesLogos: CompaniesLogoListProps[] = [
  { src: '/companies/amazon-pb.png', alt: 'Amazon', w: [130], h: [40] },
  { src: '/companies/zup-pb.png', alt: 'Zup', w: [68], h: [31] },
  { src: '/companies/hostgator-pb.png', alt: 'HostGator', w: [164], h: [29] },
  {
    src: '/companies/mercado-livre-pb.png',
    alt: 'Mercado Livre',
    w: [149],
    h: [38],
  },
  {
    src: '/companies/rank-my-app-pb.png',
    alt: 'Rank My App',
    w: [134],
    h: [48],
  },
];

export const HomePageBrands = (): ReactElement => (
  <Box bgColor='background.700'>
    <Container maxW='container.lg' paddingY={12}>
      <Text
        textTransform='uppercase'
        fontWeight={700}
        textAlign='center'
        mb={10}
        color='primary.500'
      >
        Algumas empresas que já contrataram com a geekhunter
      </Text>

      <Center>
        <Grid
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(5, 1fr)',
          }}
          maxW={{
            base: 400,
            md: 1000,
          }}
          gap={4}
        >
          {companiesLogos.map((companieLogo) => (
            <Center key={companieLogo.alt}>
              <GridItem
                key={companieLogo.alt}
                data-testid='company-logo'
                paddingX={4}
                textAlign='center'
              >
                <BrandLogo
                  src={companieLogo.src}
                  alt={companieLogo.alt}
                  h={companieLogo.h}
                  w={companieLogo.w}
                />
              </GridItem>
            </Center>
          ))}
        </Grid>
      </Center>
    </Container>
  </Box>
);
