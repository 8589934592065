import { NextSeo as BaseNextSeo, NextSeoProps } from 'next-seo';

const noIndexAndFollowPage =
  process.env.NEXT_PUBLIC_CURRENT_ENV !== 'production';

export const NextSeo = (props: Omit<NextSeoProps, 'noindex' | 'nofollow'>) => (
  <BaseNextSeo
    {...props}
    noindex={noIndexAndFollowPage}
    nofollow={noIndexAndFollowPage}
  />
);
