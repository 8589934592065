import { ReactElement } from 'react';
import { Text, TextProps, Icon } from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import { PropsWithChildren } from 'lib/@types';

export interface HeaderLinkLabelProps extends TextProps {
  renderChevron?: boolean;
  isSelected?: boolean;
  borderBottomWidth?: number;
}

const HeaderLinkLabel = ({
  renderChevron = false,
  isSelected = false,
  borderBottomWidth = 2,
  children,
  ...props
}: PropsWithChildren<HeaderLinkLabelProps>): ReactElement => {
  const selectedStyle = {
    color: 'primary.500',
    borderBottomColor: { base: 'transparent', md: 'primary.500' },
    bgColor: { base: 'primary.150', md: 'transparent' },
  };

  return (
    <Text
      _hover={selectedStyle}
      borderBottomStyle='solid'
      borderBottomWidth={borderBottomWidth}
      borderBottomColor='white.100'
      color='black.300'
      cursor='pointer'
      fontFamily='body'
      fontSize={10}
      fontWeight='700'
      letterSpacing={1.2}
      ml={{ base: 0, md: 8 }}
      px={{ base: 6, md: 0 }}
      py={{ base: 3, md: 1.5 }}
      sx={isSelected ? selectedStyle : {}}
      textTransform='uppercase'
      transition='0.2s'
      {...props}
    >
      {children}
      {renderChevron && <Icon as={FiChevronDown} h={3} w={3} ml={3} />}
    </Text>
  );
};

export default HeaderLinkLabel;
