import { ButtonProps } from '@chakra-ui/react';

export const solidNewPurple: ButtonProps = {
  _hover: {
    bgColor: 'companyPurple.500',
    opacity: 0.7,
  },
  bgColor: 'companyPurple.500',
  color: 'white',
};
