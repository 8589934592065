import { ReactElement } from 'react';
import { BoxProps, Box, Text } from '@chakra-ui/react';
import Image from 'next/image';

import { PropsWithChildren } from 'lib/@types';

export interface BenefitsCardProps extends BoxProps {
  src: string;
  alt: string;
  title: string;
  text: string;
}

const BenefitCard = ({
  src,
  alt,
  title,
  text,
  ...props
}: PropsWithChildren<BenefitsCardProps>): ReactElement => (
  <Box
    h='full'
    boxShadow='md'
    paddingY={8}
    paddingX={6}
    maxW={{ base: 320, md: 'full' }}
    mb={{ base: 6, md: 0 }}
    {...props}
  >
    <Image src={src} alt={alt} height={58} width={58} />
    <Text
      fontSize='2xl'
      fontWeight={600}
      mt={2}
      mb={6}
      lineHeight={8}
      maxW={200}
    >
      {title}
    </Text>
    <Text fontSize='md' color='text.200' maxW={240}>
      {text}
    </Text>
  </Box>
);

export { BenefitCard };
