import { ReactElement } from 'react';
import Image, { ImageProps } from 'next/image';

import GeekLogoFile from '@public/geek-logo.svg';
import GeekWhiteLogoFile from '@public/geek-logo-white.svg';
import GeekHorizontalLogoFile from '@public/logo_white_horizontal.svg';

type LogoType = 'standard' | 'white' | 'horizontal';

interface GeekLogoProps extends Partial<ImageProps> {
  type?: LogoType;
}

const logo: Record<LogoType, ImageProps> = {
  standard: {
    src: GeekLogoFile,
    width: '150px',
    height: '30px',
  },
  white: {
    src: GeekWhiteLogoFile,
    width: '165px',
    height: '80px',
  },
  horizontal: {
    src: GeekHorizontalLogoFile,
    width: '150px',
    height: '30px',
  },
};

const GeekLogo = ({
  type = 'standard',
  ...props
}: GeekLogoProps): ReactElement => (
  <>
    <Image
      data-testid='header-logo'
      alt='GeekHunter Logo'
      {...logo[type]}
      {...props}
    />
  </>
);

export default GeekLogo;
