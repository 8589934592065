import { ReactElement } from 'react';

import { Footer } from 'components/organisms/footer';
import { headerLinks } from 'lib/constants/header';
import { HomePageBanner } from 'components/organisms/home/banner';
import { HomePageBenefits } from 'components/organisms/home/benefits';
import { HomePageBrands } from 'components/organisms/home/brands';
import { HomePageCompanies } from 'components/organisms/home/companies';
import {
  HomePageJobs,
  HomePageJobsProps,
} from 'components/organisms/home/jobs';
import { HomePageStats } from 'components/organisms/home/stats';
import LandingPageHeader from 'components/organisms/landingPageHeader';
import { HomePageTestimonials } from 'components/organisms/home/testimonials';

export type SiteHomePageProps = HomePageJobsProps;

export const SiteHomePage = ({ jobs }: SiteHomePageProps): ReactElement => (
  <>
    <LandingPageHeader headerLinks={headerLinks} />

    <HomePageBanner />
    <HomePageBrands />
    <HomePageStats />
    <HomePageBenefits />
    <HomePageCompanies />
    <HomePageTestimonials />
    <HomePageJobs jobs={jobs} />

    <Footer />
  </>
);
