import * as paths from 'lib/constants/paths';

export const formatTechs = (
  techs: Array<{ name: string; urlPath: string }>
): Array<{ name: string; urlPath: string }> => {
  const displayTechs = techs.slice(0, 3).map((tech) => {
    const result = { ...tech };
    result.urlPath = paths.TECHS_JOBS(tech.urlPath);
    return result;
  });

  if (techs.length > 3) {
    displayTechs.push({
      name: `+ ${techs.length - 3}`,
      urlPath: paths.JOBS,
    });
  }
  return displayTechs;
};
