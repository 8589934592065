import { ThemeConfig, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import { sharedColorsScheme } from 'lib/settings/theme/colors/shared';

import { ThemeColors } from './types';

const sizes = {
  container: {
    xxl: '1600px',
  },
};

const space = {
  18: '4.5rem',
  128: '32rem',
};

const fonts = {
  heading: "'Poppins', sans-serif",
  body: "'Open Sans', sans-serif",
};

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
});

const config: ThemeConfig = {
  initialColorMode: 'light',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildTheme = (colors: ThemeColors): Record<string, any> =>
  extendTheme({
    scrollBehavior: 'smooth',
    colors: {
      ...sharedColorsScheme,
      ...colors,
    },
    fonts,
    space,
    breakpoints,
    config,
    sizes,
    components: {
      Button: {
        sizes: {
          xl: {
            py: 8,
            px: 8,
            w: 400,
            h: '50px',
          },
          xxl: {
            py: 8,
            px: 8,
            w: 400,
            h: '64px',
          },
        },
      },
    },
  });
