
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ReactElement } from 'react';
import { GetStaticProps } from 'next';

import { apolloClientSsr } from 'lib/settings/apolloClient';
import { companyColorScheme } from 'lib/settings/theme/colors/company';
import { FindLatestApprovedJobsQuery } from 'lib/generated/graphql';
import { SiteHomePage } from 'components/templates/home';
import * as path from 'lib/constants/paths';
import findLatestApprovedJobs from 'lib/graphql/queries/jobs/findLatestApprovedJobs';
import { ThemeProvider } from 'components/atoms/themeProvider';
import { NextSeo } from 'components/atoms/nextSeo';

const monolithLink =
  process.env.NEXT_PUBLIC_MONOLITH_PATH || 'https://geekhunter.com.br';

export interface HomePageProps {
  jobs: FindLatestApprovedJobsQuery['findLatestApprovedJobs'];
}

const getLastApprovedJobs = async () => {
  const jobsQuery = await apolloClientSsr.query<FindLatestApprovedJobsQuery>({
    query: findLatestApprovedJobs,
  });

  const jobs = jobsQuery?.data?.findLatestApprovedJobs;

  return jobs;
};

const Index = ({ jobs }: HomePageProps): ReactElement => (
  <ThemeProvider colors={companyColorScheme}>
    <NextSeo
      title='Geekhunter: Recrute Talentos tech | Vagas para profissionais tech'
      description='Conectamos os melhores profissionais de tecnologia às empresas mais desejadas!'
      canonical={`${monolithLink}${path.ROOT}`}
    />

    <SiteHomePage jobs={jobs} />
  </ThemeProvider>
);

 const _getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const jobs = await getLastApprovedJobs();

  return {
    props: {
      jobs,
    },
    revalidate: 3600,
  };
};

export default Index;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  