import { ReactElement } from 'react';
import { Box, Container, Text, Center, Grid, GridItem } from '@chakra-ui/react';
import Link from 'next/link';

import Heading from 'components/atoms/heading';
import Button from 'components/atoms/button';
import { BenefitCard } from 'components/molecules/home/benefitsCard';
import { JobSearchType } from 'components/molecules/home/jobSearchType';
import * as paths from 'lib/constants/paths';

export const HomePageBenefits = (): ReactElement => (
  <Box bgColor='background.700'>
    <Container maxW='container.lg' paddingY={12}>
      <Text
        textTransform='uppercase'
        color='primary.500'
        fontWeight={700}
        textAlign='center'
        mb={4}
      >
        O processo seletivo na plataforma
      </Text>
      <Heading as='h2' textAlign='center' fontWeight={600} mb={10}>
        Por que profissionais amam a Geek?
      </Heading>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        gap={{ base: 0, md: 4 }}
        mb={{ base: 64, sm: 72, md: 80, lg: 96 }}
        justifyItems='center'
      >
        <GridItem justifySelf={{ base: 'auto', lg: 'right' }}>
          <JobSearchType
            src='/benefits/running-girl.png'
            imageHeight={837}
            imageWidth={697}
            alt='Garota correndo com pressa.'
            title='Tradicional'
            subtitle='Você vai até as empresas'
            text='Você acha uma vaga e corre para preenchê-la o mais rápido possível. Encontrando outra você deve fazer tudo de novo.'
            mb={{ base: 56, md: 'auto' }}
          />
        </GridItem>
        <GridItem justifySelf={{ base: 'auto', lg: 'left' }}>
          <JobSearchType
            src='/benefits/walking-guy.png'
            imageHeight={946}
            imageWidth={728}
            alt='Garoto andando tranquilo.'
            title='GeekHunter'
            subtitle='As empresas vão até você'
            text='Preencha seu perfil uma única vez e receba oportunidades de trabalho coerentes com o seu histórico profissional e interesses.'
          />
        </GridItem>
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(3, 1fr)',
        }}
        gap={4}
        mb={12}
        justifyItems={{ base: 'center', xl: 'unset' }}
        alignItems='stretch'
      >
        <GridItem>
          <BenefitCard
            src='/benefits/job-proposals.png'
            alt='Papéis com propostas de emprego.'
            title={`Compare\npropostas`}
            text='Você está no controle. Deixe as empresas irem até você. Compare
      vagas, receba convites de entrevista e conheça a faixa de remuneração antes de
      conversar com a empresa.'
          />
        </GridItem>
        <GridItem>
          <BenefitCard
            src='/benefits/privacy.png'
            alt='Rosto com apenas chapéu e óculos.'
            title={`Privacidade\nde perfil`}
            text='Oculte seu perfil para empregadores antigos e atuais. Nós só iremos
          te recomendar para vagas que tenham a ver com o seu
          perfil.'
          />
        </GridItem>
        <GridItem>
          <BenefitCard
            src='/benefits/shinning-coins.png'
            alt='Pilha de moedas reluzindo.'
            title={`Gratuito para \nprofissionais`}
            text='Conte sempre com a GeekHunter. Estamos aqui para ajudar 
          profissionais de tecnologia. Você não paga nada para utilizar a
          plataforma!'
          />
        </GridItem>
      </Grid>
      <Center>
        <Link href={paths.CANDIDATE_LP} passHref>
          <Button p={4}>Criar conta como profissional</Button>
        </Link>
      </Center>
    </Container>
  </Box>
);
