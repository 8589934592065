import { ReactElement } from 'react';
import { Box, Container, Flex, Text, Grid, GridItem } from '@chakra-ui/react';

import Heading from 'components/atoms/heading';
import { StatsBox } from 'components/atoms/statsBox';
import { starredText } from 'lib/utils/textUtils';

export interface CompaniesLogoListProps {
  src: string;
  alt: string;
  w: number[] | Record<string, number>;
  h: number[] | Record<string, number>;
}

export const HomePageStats = (): ReactElement => (
  <Box bgColor='background.200'>
    <Container maxW='container.lg' paddingY={12}>
      <Text
        textTransform='uppercase'
        fontWeight={700}
        textAlign='center'
        color='primary.500'
        letterSpacing='1px'
        mb={4}
      >
        OS NÚMEROS PODEM DIZER MAIS
      </Text>

      <Flex alignItems='flex-start' justifyContent='center' mb={10}>
        <Heading as='h2' maxWidth={700} textAlign='center'>
          {starredText(
            'A GeekHunter é eficiente para profissionais e empresas de tecnologia',
            ['profissionais', 'e', 'empresas', 'de', 'tecnologia'],
            {
              fontWeight: '700',
            }
          )}
        </Heading>
      </Flex>

      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(3, 1fr)',
        }}
        gap={4}
        justifyItems={{ base: 'center', md: 'stretch' }}
      >
        <GridItem>
          <StatsBox title='+5 mil' text='oportunidades <br /> de trabalho' />
        </GridItem>
        <GridItem>
          <StatsBox title='+500' text='empresas <br /> contratando' />
        </GridItem>
        <GridItem>
          <StatsBox title='+130 mil' text='convites para <br /> entrevistas' />
        </GridItem>
      </Grid>
    </Container>
  </Box>
);
