import { forwardRef, Text, TextProps, useToken } from '@chakra-ui/react';

export interface BadgeTechProps extends TextProps {
  text: string;
  textColor: string;
  borderColor: string;
  hoverBgColor: string;
  hoverTextColor: string;
}

export const BadgeTech = forwardRef<BadgeTechProps, 'text'>(
  (
    { text, textColor, borderColor, hoverBgColor, hoverTextColor, ...props },
    ref
  ) => {
    const hoverBgColorToken = useToken('colors', hoverBgColor);
    const hoverTextColorToken = useToken('colors', hoverTextColor);

    return (
      <Text
        ref={ref}
        fontWeight={700}
        textTransform='uppercase'
        borderColor={borderColor}
        borderRadius={6}
        borderStyle='solid'
        borderWidth={2.5}
        color={textColor}
        fontSize='xs'
        letterSpacing='widest'
        px={2.5}
        py={1}
        _hover={{
          bgColor: hoverBgColorToken,
          color: hoverTextColorToken,
        }}
        {...props}
      >
        {text}
      </Text>
    );
  }
);

BadgeTech.displayName = 'BadgeTech';
