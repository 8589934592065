import { ReactElement, useMemo } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { ThemeColors } from 'lib/settings/theme/types';
import { PropsWithChildren } from 'lib/@types';
import { buildTheme } from 'lib/settings/theme';

export interface ThemeProviderProps {
  colors: ThemeColors;
}

export const ThemeProvider = ({
  children,
  colors,
}: PropsWithChildren<ThemeProviderProps>): ReactElement => {
  const theme = useMemo(() => buildTheme(colors), [colors]);

  return (
    <ChakraProvider resetCSS theme={theme}>
      {/* Make Color mode to persists when you refresh the page. */}
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />

      {children}
    </ChakraProvider>
  );
};
