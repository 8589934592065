import { ReactElement } from 'react';
import { Box, BoxProps, Text } from '@chakra-ui/react';

import Heading from 'components/atoms/heading';
import { PropsWithChildren } from 'lib/@types';
import { starredText } from 'lib/utils/textUtils';

export interface CompaniesCardProps extends BoxProps {
  title: string;
  starredTitle: string[];
  subtitle?: string;
  text: string;
}

const CompaniesCard = ({
  subtitle,
  text,
  title,
  starredTitle,
  ...props
}: PropsWithChildren<CompaniesCardProps>): ReactElement => (
  <Box
    background='background.700'
    p={{ base: 6, sm: 12 }}
    boxShadow='md'
    {...props}
  >
    <Text textTransform='uppercase' fontWeight={700} color='primary.500'>
      {subtitle}
    </Text>
    <Heading as='h3' mt={0} maxWidth={450} paddingY={8}>
      {starredText(title, starredTitle, {
        fontWeight: '700',
      })}
    </Heading>
    <Text maxWidth={450}>{text}</Text>
  </Box>
);

export { CompaniesCard };
