import { BadgeProps } from '@chakra-ui/react';

import { remote } from './remote';
import { rawText } from './rawText';

export type VariantsAvailable = 'remote' | 'rawText';

export const variants: Record<VariantsAvailable, BadgeProps> = {
  remote,
  rawText,
};
