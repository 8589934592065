import { ThemeColors } from '../types';

export const sharedColorsScheme: ThemeColors = {
  purple: {
    100: '#DBCEF5',
    200: '#F7E7FF',
    300: '#DFA7FE',
  },
  blue: {
    100: '#1E90FF',
    200: '#41A9B9',
    800: '#1C202B',
    900: '#090917',
  },
  black: {
    300: '#000000',
    400: '#C7CBCF',
    500: '#ACACAD',
    600: '#3A3A3A',
    650: '#6B757D',
    700: '#11273a',
    750: '#202024',
    800: '#18171B',
    900: '#040C10',
  },
  neutralBlack: {
    100: '#F5F5F7',
    200: '#E1E2EB',
    300: '#DABBCC',
    400: '#9B9DBZ',
    500: '#77798C',
    600: '#545669',
    700: '#363847',
    800: '#1C202B',
    900: '#090917',
  },
  neutralWhite: {
    100: '#FCFCFC',
  },
  grey: {
    100: '#CBD5E0',
    200: '#444A51',
    300: '#E5F0FD',
    400: '#C1C6CC',
    500: '#BABBCC',
    600: '#D2D5D8',
    800: '#737380',
    900: '#6B757D',
  },
  white: {
    100: '#FFFFFF',
    200: '#F2F4F6',
  },
  green: {
    100: '#EBF7F4',
    200: '#CEF0EA',
    300: '#9EDED8',
    400: '#6FCCC3',
    500: '#47BFAF',
    600: '#00A393',
    700: '#00857C',
    800: '#006664',
    900: '#004B4F',
    950: '#3BB49D',
  },
  red: {
    100: '#E62F5C',
  },
  companyPurple: {
    100: '#be70ea',
    200: '#b459e6',
    300: '#a941e3',
    400: '#9e29df',
    500: '#9412DC',
    600: '#8510c6',
    700: '#760eb0',
    800: '#670c9a',
    900: '#580a84',
  },
};
