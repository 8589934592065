import { TextProps, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

import Heading from 'components/atoms/heading';

export interface StatsBoxProps extends TextProps {
  title: string;
  text: string;
}

export const StatsBox = ({
  title,
  text,
  ...props
}: StatsBoxProps): ReactElement => (
  <>
    <Box
      background='background.700'
      width={{ base: 280, sm: 300, md: 'full' }}
      paddingY={6}
      {...props}
    >
      <Heading
        as='h2'
        textAlign='center'
        color='primary.500'
        fontSize='5xl'
        mt={0}
        paddingTop={0}
      >
        {title}
      </Heading>
      <Heading
        as='h3'
        mt={0}
        textAlign='center'
        fontSize='2xl'
        dangerouslySetInnerHTML={{ __html: text }}
        paddingBottom={2}
      />
    </Box>
  </>
);
