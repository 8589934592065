import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import Image from 'next/image';

import CircleOne from '@public/home/circle-1.svg';
import CircleTwo from '@public/home/circle-2.svg';

export const Circles = (): ReactElement => (
  <>
    <Box
      bottom={-36}
      display={{ base: 'none', lg: 'block' }}
      position='absolute'
      right={-12}
    >
      <Image
        alt='Circulo 1'
        height='300px'
        layout='fixed'
        src={CircleOne}
        width='300px'
      />
    </Box>

    <Box
      bottom='20%'
      display={{ base: 'none', lg: 'block' }}
      left={-4}
      position='absolute'
    >
      <Image
        alt='Circulo 2'
        height='313px'
        layout='fixed'
        src={CircleTwo}
        width='250px'
      />
    </Box>
  </>
);
